.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  
}

.map {
  position: relative;
  flex: 1;
  overflow: hidden;
}

.noData {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff;
  background: #1d1d1e;
}
