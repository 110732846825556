@use 'src/styles/utils' as *;

.leaf {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-leaf);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: var(--color-white);
  font-weight: 400;
  font-family: var(--leaf-font-family);
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 1px rgb(0 0 0 / 40%);
}

.imageContainer {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 0 0 12px;
}

.imageCover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.onLoad {
  .imageCover {
    display: none;
  }
}

.forceLoad {
  .imageCover {
    display: inline-block!important;
  }
}

.mockLoad {
  display: inline-block;
}

.infoBlock {
  display: block;
  width: 100%;
  padding: 2% 5%;
  text-align: left;
}

.name {
  position: relative;
  width: 100%;
  font-weight: 600;
  @include number-lines(1);
}

.secondaryBlock {
  white-space: nowrap;
}

.floorPice {
  position: absolute;
  top: var(--space-40);
  right: var(--space-40);
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: calc(100% - 20px);
  padding: 0.1em .2em;
  font-size: var(--title-regular);
  font-family: var(--app-font-family);
  background: rgb(0 0 0 / 30%);
  border-radius: 100px
}

.floorPiceValue {
  // max-width: calc(100% - 20px);
  margin-left: 0.1em;
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
}

.percentDash {
  margin-left: 0.2em;
  color: white!important;
  font-size: 80%;
}