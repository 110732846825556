.toggle {
  position: relative;
  display: inline-block;
  width: var(--size-80);
  height: var(--size-45);
  margin-left: var(--space-20);
  background-color: var(--color-surface-300);
  border: none;
  border-radius: var(--size-125);
  outline: none;
  cursor: pointer;
  transition: background-color ease 0.3s;
  appearance: none;

  &::before {
    position: absolute;
    top: var(--size-10);
    left: var(--size-05);
    z-index: 2;
    display: block;
    width: var(--size-25);
    height: var(--size-25);
    background: var(--color-white);
    border-radius: var(--radius-50);
    transition: all 0.3s;
    content: '';
  }
}

.checked {
  background-color: var(--color-brand-primary);

  &::before {
    left: var(--space-50);
  }
}

.label {
  display: flex;
  align-items: center;
  font-size: var(--size-35);
}
