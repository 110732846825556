@use 'src/styles/utils' as *;

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  background: #1d1f1e;
}

.label {
  margin-right: 5px;
  color: #fff;
  font-size: 14px;
}

.button {
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  opacity: 0.8;
}

.viewtype {
  display: flex;
  margin: 0;
  color: #fff;
  font-size: 14px;
  background: #121212;
  border: none;
  outline: none;
  cursor: pointer;
  

  &:hover {
    background-color: #3e4141;
  }
}

.active {
  background: var(--color-primary-lightgreen);
  cursor: default;
  opacity: 1 !important;
}

.headerView {
  position: relative;
  display: flex;
  align-items: center;
  width: 80px;
  height: 24px;
  background: #84c47c;
  border-radius: 12px;
  box-shadow: 0 0 2px rgb(133 190 133 / 75%),
    inset 0 1px 1px rgb(51 50 54 / 40%);

  & .control {
    position: absolute;
    width: 40px;
    height: 24px;
    background: #333236;
    border-radius: 12px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 25%);
    transition: 0.25s ease-in;

    &.left {
      left: 0;
    }

    &.right {
      left: 40px;
    }
  }
}

.iconContainer a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  filter: var(--filter-color-surface-100);
}
