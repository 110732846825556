@use 'src/styles/utils' as *;

.container {
  position: absolute;
  bottom: 20px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;
  background: rgb(0 0 0 / 70%);
  border-radius: 5px;
}
