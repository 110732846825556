@use '../utils.scss' as *;

:root {
  // colors
  --color-white: rgb(255 255 255 / 100%);
  --color-black: rgb(0 0 0 / 100%);
  --color-brand-primary: rgb(140 191 132 / 100%);
  --color-brand-secondary: rgb(222 120 117 / 100%);
  --color-brand-tertiary: rgb(220 220 220 / 100%);
  --color-surface-100: rgb(120 120 120 / 100%);
  --color-surface-200: rgb(107 107 107 / 100%);
  --color-surface-300: rgb(95 95 95 / 100%);
  --color-surface-400: rgb(82 82 82 / 100%);
  --color-surface-500: rgb(69 69 69 / 100%);
  --color-surface-600: rgb(55 55 55 / 100%);
  --color-surface-700: rgb(43 43 43 / 100%);
  --color-surface-800: rgb(30 30 30 / 100%);
  --color-surface-900: rgb(18 18 18 / 100%);
  --color-heatmap-positive-5: rgb(92 132 86 / 100%);
  --color-heatmap-positive-10: rgb(49 92 50 / 100%);
  --color-heatmap-neutral: rgb(171 171 171 / 100%);
  --color-heatmap-negative-5: rgb(186 74 69 / 100%);
  --color-heatmap-negative-10: rgb(157 47 41 / 100%);
  --color-text-lo: rgb(255 255 255 / 40%);
  --color-text-md: rgb(255 255 255 / 60%);
  --color-text-hi: rgb(255 255 255 / 90%);
  --color-function-danger: rgb(214 31 31 / 100%);
  --color-function-info: rgb(12 102 255 / 100%);
  --color-function-success: rgb(132 196 124 / 100%);
  --color-function-warning: rgb(255 216 76 / 100%);
  --color-primary-lightgreen: #7CC07C;
  
  // Hover
  --color-hover-darkgreen: #192E1A;

  // hexadecimal colors
  --gray-600: #313333;
  --gray-800: #1d1f1e;

  // colors by filters for svg images
  --filter-color-brand-primary: invert(72%) sepia(19%) saturate(637%) hue-rotate(65deg) brightness(96%) contrast(88%);
  --filter-color-surface-100: invert(47%) sepia(0%) saturate(25%) hue-rotate(137deg) brightness(99%) contrast(88%);

  // border
  --color-border-md: hsl(0deg 0% 100% / 20%);

  // fonts
  --app-font-family: 'Roboto', arial, helvetica, sans-serif;
  --leaf-font-family: 'PlexSans', sans-serif;

  // fontSizes
  --app-font-size: #{$app-font-size + px};
  --title-1: #{px-to-rem(36)};
  --title-2: #{px-to-rem(32)};
  --title-3: #{px-to-rem(28)};
  --title-4: #{px-to-rem(24)};
  --title-5: #{px-to-rem(20)};
  --title-6: #{px-to-rem(18)};
  --text-lead: #{px-to-rem(16)};
  --text-regular: #{px-to-rem(15)};
  --text-small: #{px-to-rem(12)};
  --text-xsmall: #{px-to-rem(10)};
  --text-custom-22: #{px-to-rem(22)};

  // lineHeights
  --leading-21: 21px;

  // radius
  --radius-xs: 2px;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-xl: 22px;
  --radius-50: 50%;
  --radius-6: 6px;



  // shadows
  --text-shadow: 0 0.74px 0 rgb(0 0 0 / 40%);

  // sizes
  --size-01: 1px;
  --size-05: 2px;
  --size-10: 4px;
  --size-15: 6px;
  --size-20: 8px;
  --size-25: 10px;
  --size-30: 12px;
  --size-35: 14px;
  --size-40: 16px;
  --size-45: 18px;
  --size-50: 20px;
  --size-60: 24px;
  --size-70: 28px;
  --size-80: 32px;
  --size-90: 36px;
  --size-100: 40px;
  --size-110: 44px;
  --size-120: 48px;
  --size-125: 50px;
  --size-140: 56px;
  --size-160: 64px;
  --size-180: 72px;
  --size-200: 80px;
  --size-240: 96px;
  --size-280: 112px;
  --size-320: 128px;

  // space
  --space-05: 2px;
  --space-10: 4px;
  --space-15: 6px;
  --space-20: 8px;
  --space-25: 10px;
  --space-30: 12px;
  --space-35: 14px;
  --space-40: 16px;
  --space-50: 20px;
  --space-60: 24px;
  --space-70: 28px;
  --space-80: 32px;
  --space-90: 36px;
  --space-100: 40px;
  --space-110: 44px;
  --space-120: 48px;
  --space-140: 56px;
  --space-160: 64px;
  --space-200: 80px;
  --space-240: 96px;
  --space-280: 112px;
  --space-320: 128px;

  // transitions
  --tran-fn-ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);
  --tran-fn-ease-out: cubic-bezier(0, 0, 0.2, 1);
  --tran-fn-ease-in: cubic-bezier(0.4, 0, 1, 1);
  --tran-fn-sharp: cubic-bezier(0.4, 0, 0.6, 1);
  --duration-shortest: 150ms;
  --duration-shorter: 200ms;
  --duration-short: 250ms;
  --duration-standard: 300ms;
  --duration-complex: 375ms;
  --duration-entering-screen: 225ms;
  --duration-leaving-screen: 195ms;

  // zIndices
  --z-index-tray-overlay: 100;
  --z-index-leaf: 2;
  --z-index-popover: 100;
}
