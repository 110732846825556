.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.imageContainer {
  margin-bottom: var(--space-60);
}

.text {
  margin-bottom: var(--space-40);
  color: var(--color-text-hi);
  font-size: var(--text-lead);
}
