.container {
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;

  a {
    text-decoration: underline;
  }

  .button {
    border: none;
    background: none;
    font-weight: normal;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
  }
}

.icon {
  flex-shrink: 0;
}
