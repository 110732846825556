.underlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  background-color: rgb(0 0 0 / 40%);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  &.isopen {
    visibility: visible;
    opacity: 0.9999;
    transition-delay: 0ms;
    pointer-events: auto;
  }
}

.traywrapper {
  position: fixed;
  top: 0;
  z-index: var(--z-index-tray-overlay);
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  inset-inline-start: 0;
}


.tray {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: var(--size-160);
  max-height: calc(var(--visual-viewport-height) - var(--size-160));
  padding: var(--space-20);
  background-color: var(--color-surface-700);
  border-radius: var(--space-20) var(--space-20) 0 0;
  outline: none;
  transform: translateY(100%);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  &.isopen {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateY(0);
    visibility: visible;
    opacity: 0.9999;
    transition-delay: 0ms;
    pointer-events: auto;
  }
}
