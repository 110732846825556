.chip {
  display: inline-flex;
  align-items: center;
  height: var(--size-70);
  padding: 0 var(--space-40);
  font-size: var(--text-small);
  background: var(--color-surface-600);
  border-radius: var(--radius-xl);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--space-40);
  height: var(--space-40);
  margin-right: var(--space-20);
  margin-left: calc(var(--space-10) * -1);
  overflow: hidden;
  border-radius: var(--radius-50);
}
