@use 'src/styles/utils' as *;

.menuItem {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 8px 16px;

  @media (max-width: 460px) {
    padding: 4px 16px;
  }
}

.activeMenuItem {
  background-color: var(--color-primary-lightgreen);
}

.title {
  margin: 0;
  color: rgb(255 255 255 / 60%);
  font-size: 16px;

  @media (max-width: 460px) {
    font-size: 14px;
  }
}

.activeTitle {
  color: #000;
}

.title:hover {
  color: #6ec56e;
}

.activeTitle:hover {
  color: #000;
}
