/* stylelint-disable selector-id-pattern */
html,
body,
#__next,
#overlay-provider {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  background-color: #1d1f1e;
}
