[data-theme='light'] {
  --color-surface-100: rgb(249 249 249 / 100%);
  --color-surface-200: rgb(244 244 245 / 100%);
  --color-surface-300: rgb(240 240 240 / 100%);
  --color-surface-400: rgb(235 236 236 / 100%);
  --color-surface-500: rgb(230 231 232 / 100%);
  --color-surface-600: rgb(225 226 227 / 100%);
  --color-surface-700: rgb(220 221 222 / 100%);
  --color-surface-800: rgb(215 217 218 / 100%);
  --color-surface-900: rgb(210 212 214 / 100%);
  --color-text-lo: rgb(0 0 0 / 40%);
  --color-text-md: rgb(0 0 0 / 60%);
  --color-text-hi: rgb(0 0 0 / 90%);
}
