@import 'src/styles/utils';

.picker {
  position: relative;
  display: inline-flex;
  align-self: flex-start;
  min-width: 160px;

  @include breakpoint('lg') {
    width: 100%;
  }
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: var(--space-20) var(--space-70) var(--space-20) var(--space-25);
  color: var(--color-text-hi);
  font-weight: 500;
  background-color: var(--color-surface-700);
  border: none;
  border-radius: var(--size-05);

  &::after {
    position: absolute;
    top: 50%;
    right: var(--size-25);
    width: var(--size-25);
    height: var(--size-40);
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxMCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgNkw1IDEwTDkgNiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLW9wYWNpdHk9IjAuNCIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=');
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(-50%);
    content: '';
  }

  &:focus {
    outline: none;
  }

  @include breakpoint('lg') {
    justify-content: space-between;
    padding: var(--space-50) var(--space-100) var(--space-50) var(--space-40);
    font-size: var(--size-40);
    line-height: var(--size-60);
    border: 1px solid var(--color-surface-600);
    border-radius: var(--size-10);

    &::after {
      width: var(--size-24);
      height: var(--size-24);
    }
  }
}

.label {
  margin-right: var(--space-20);
  color: var(--color-text-md);
  font-size: var(--size-35);
  line-height: var(--size-40);
  letter-spacing: -0.01em;

  @include breakpoint('lg') {
    font-size: var(--size-40);
    line-height: var(--size-60);
  }
}

.valueWrapper {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  margin-right: var(--space-20);
}
