.progressBar {
  width: 100%;
}

.label {
  display: flex;
  justify-content: space-between;
}

.progressBarBackground {
  height: var(--size-15);
  background: var(--color-surface-600);
  border-radius: var(--radius-sm);
}

.progressBarPercent {
  height: var(--size-15);
  background: var(--color-function-info);
  border-radius: var(--radius-sm);
}
