.menuDesktop {
  display: flex;
  align-items: center;
  height: 40px;
  background-color: #000;
}

.menuMobile {
  height: 40px;
}
