// nested classes are use to avoid specificity from global a styles
.anchor {
  cursor: pointer;
  transition: color var(--tran-fn-ease-in) var(--duration-short);

  &.hi {
    color: var(--color-text-hi);
  }

  &.hiHover {
    color: var(--color-text-md);
  }

  &.md {
    color: var(--color-text-md);
  }

  &.mdHover {
    color: var(--color-text-md);
    text-decoration: underline;
  }

  &.brand {
    color: var(--color-brand-primary);
  }

  &.brandHover {
    color: var(--color-text-md);
  }
}
