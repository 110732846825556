.provider {
  position: relative;
  z-index: 100;
  background: transparent;
  -webkit-tap-highlight-color: #0000;
}

.isolate {
  isolation: isolate;
}
