.underlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  background-color: rgb(0 0 0 / 50%);
}

.dialog {
  margin: var(--space-40);
  background-color: var(--color-surface-700);

  &:focus {
    outline: 0;
  }
}

.title {
  flex: 1;
  margin: 0;
}

.padding {
  padding: var(--space-40);
}

.small {
  max-width: 400px;
}

.medium {
  max-width: 600px;
}

.large {
  max-width: 900px;
}

.xlarge {
  max-width: 1200px;
}

.close {
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.header {
  display: flex;
}
