.filter {
  position: relative;
  z-index: 10;
  display: flex;
  margin: var(--space-10) 0;
  margin-top: 12px;
  margin-bottom: .5rem;
  padding: 0 8px;
}

.left {
  display: flex;
  flex: 1;
}

.right {
  display: flex;
  align-items: center;
}

.items {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  transform: translateX(-150%);
  transition: 0.15s ease;

  &.visible {
    transform: translateX(0);
    transition: 0.15s ease;
  }

  & > * {
    margin-right: var(--space-10);
  }
}

// .header { not in use? 29/2
//   display: flex;
//   align-items: center;
//   padding: var(--space-40);
//   font-size: var(--text-lead);
//   border-bottom: 1px solid var(--color-border-md);
// }

// .title {  not in use? 29/2
//   flex: 1;
//   margin: 0 8px;
//   text-align: center;
// }

.back {
  width: var(--size-60);
  height: var(--size-60);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNi4xOTY3IDMuMzUyNTJDMTUuNzI2NyAyLjg4MjQ5IDE0Ljk2ODkgMi44ODI0OSAxNC40OTg5IDMuMzUyNTJMNi41Mjc2NSAxMS4zMjM3QzYuMTUzNTUgMTEuNjk3OCA2LjE1MzU1IDEyLjMwMjIgNi41Mjc2NSAxMi42NzYzTDE0LjQ5ODkgMjAuNjQ3NUMxNC45Njg5IDIxLjExNzUgMTUuNzI2NyAyMS4xMTc1IDE2LjE5NjcgMjAuNjQ3NUMxNi42NjY3IDIwLjE3NzUgMTYuNjY2NyAxOS40MTk3IDE2LjE5NjcgMTguOTQ5Nkw5LjI1MTg3IDExLjk5NTJMMTYuMjA2MyA1LjA0MDc3QzE2LjY2NjcgNC41ODAzNCAxNi42NjY3IDMuODEyOTUgMTYuMTk2NyAzLjM1MjUyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
}

.close {
  width: var(--size-60);
  height: var(--size-60);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOC42ODk0IDUuMzIxMkMxOC4yNzUzIDQuOTA3MDkgMTcuNjA2NCA0LjkwNzA5IDE3LjE5MjMgNS4zMjEyTDEyIDEwLjUwMjhMNi44MDc3NCA1LjMxMDU4QzYuMzkzNjMgNC44OTY0NyA1LjcyNDY5IDQuODk2NDcgNS4zMTA1OCA1LjMxMDU4QzQuODk2NDcgNS43MjQ2OSA0Ljg5NjQ3IDYuMzkzNjMgNS4zMTA1OCA2LjgwNzc0TDEwLjUwMjggMTJMNS4zMTA1OCAxNy4xOTIzQzQuODk2NDcgMTcuNjA2NCA0Ljg5NjQ3IDE4LjI3NTMgNS4zMTA1OCAxOC42ODk0QzUuNzI0NjkgMTkuMTAzNSA2LjM5MzYzIDE5LjEwMzUgNi44MDc3NCAxOC42ODk0TDEyIDEzLjQ5NzJMMTcuMTkyMyAxOC42ODk0QzE3LjYwNjQgMTkuMTAzNSAxOC4yNzUzIDE5LjEwMzUgMTguNjg5NCAxOC42ODk0QzE5LjEwMzUgMTguMjc1MyAxOS4xMDM1IDE3LjYwNjQgMTguNjg5NCAxNy4xOTIzTDEzLjQ5NzIgMTJMMTguNjg5NCA2LjgwNzc0QzE5LjA5MjkgNi40MDQyNSAxOS4wOTI5IDUuNzI0NjkgMTguNjg5NCA1LjMyMTJaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
}

.body {
  flex: 1;
  padding: var(--space-40);
  overflow-y: auto;

  & > * {
    margin-bottom: var(--space-30);
  }
}

// .footer { Not in use? 29/2
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: var(--space-40);
//   background: var(--color-surface-700);
// }

.resetButton {
  align-self: flex-start;
}

.btn {
  flex-basis: calc(50% - var(--space-05));
}


.mobileWrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 4px;
}