.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    width: 400px;
    margin: 0;
    padding: 16px;
    text-align: center;
    background-color: #2a2d2d;
    border-radius: 8px;
    box-shadow: 1px 1px 10px rgb(0 0 0 / 16%);
  }
  
  .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    background-color: #84c47c;
    border-radius: 50%;
  }
  
  .continueButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    margin-top: 20px;
    color: black;
    background-color: #84c47c;
    border: none;
    border-radius: 6px;
  }
  
  .title {
      margin-bottom: 0;
      font-size: 22px;
  }
  
  