.checkboxWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  user-select: none;

  & .input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
  }

  & .checkmarkBorder {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: transparent;
    border: 2px solid #777978;
    border-radius: 3px;
    transition: all 150ms;

    &.active {
      border: none;
    }

    & .checkmark {
      visibility: hidden;

      &.show {
        visibility: visible;
      }
    }
  }

  & .label {
    display: block;
    margin-left: 7px;
    color: #fff;
    font-size: 14px;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }
  }
}
