@import 'src/styles/utils';

.ul {
  min-width: 203px;
  max-height: 150px;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  list-style: none;

  &::-webkit-scrollbar {
    width: var(--size-15);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-heatmap-neutral);
    border-radius: var(--size-15);
  }

  &:focus {
    outline: none;
  }

  @include breakpoint('lg') {
    max-height: 100%;
  }
}

.option {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--space-20) var(--space-40);
  color: var(--color-text-hi);
  font-size: var(--text-regular);
  line-height: var(--size-60);
  border-radius: var(--size-10);
  outline: none;
  cursor: pointer;

  &::after {
    position: absolute;
    top: 50%;
    right: var(--space-40);
    width: var(--size-60);
    height: var(--size-60);
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(-50%);
    content: '';
  }

  &:hover {
    background-color: var(--color-surface-600);
  }

  &.selected {
    background-color: var(--color-surface-600);

    &::after {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxOCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02LjAwMDEyIDEwLjE5OTlMMi41MDAxMiA2LjY5OTk1QzIuMTEwMTIgNi4zMDk5NSAxLjQ5MDEyIDYuMzA5OTUgMS4xMDAxMiA2LjY5OTk1QzAuNzEwMTE3IDcuMDg5OTUgMC43MTAxMTcgNy43MDk5NSAxLjEwMDEyIDguMDk5OTVMNS4yOTAxMiAxMi4yODk5QzUuNjgwMTIgMTIuNjc5OSA2LjMxMDEyIDEyLjY3OTkgNi43MDAxMiAxMi4yODk5TDE3LjMwMDEgMS42OTk5NUMxNy42OTAxIDEuMzA5OTUgMTcuNjkwMSAwLjY4OTk0NiAxNy4zMDAxIDAuMjk5OTQ2QzE2LjkxMDEgLTAuMDkwMDUzNyAxNi4yOTAxIC0wLjA5MDA1MzcgMTUuOTAwMSAwLjI5OTk0Nkw2LjAwMDEyIDEwLjE5OTlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
    }
  }

  &.focused {
    background-color: var(--color-surface-600);
  }

  &.disabled {
    background-color: var(--color-surface-100);
  }
}

.icon {
  display: flex;
  margin-right: var(--space-20);
}
