.underlay {
  position: fixed;
  top: 0;
  right: 2;
  bottom: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  background-color: rgb(0 0 0 / 40%);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  &.isopen {
    visibility: visible;
    opacity: 0.9999;
    transition-delay: 0ms;
    pointer-events: auto;
  }
}

.traywrapper {
  position: fixed;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  inset-inline-start: 0;
}


.tray {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 425px;
  min-height: 100%;
  max-height: 100%;
  background-color: var(--color-surface-800);
  border-radius: var(--radius-md) var(--radius-md) 0 0;
  outline: none;
  transform: translateY(100%);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  &.isopen {
    transform: translateY(0);
    visibility: hidden;
    opacity: 1;
    transition-delay: 0ms;
    pointer-events: auto;
  }
}
