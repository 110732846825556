@use 'src/styles/utils' as *;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
}

.dropdown {
  position: relative;
  display: flex;
}

.dropdownParent {
  border-radius: 50%;
  cursor: pointer;
}

.dropdownContent {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 1;
  display: none;
  width: max-content;
  padding: 4px;
  background: #2A2D2D;
  border: 1px solid #3F4242;
  border-radius: 8px;
}

.dropdown:hover .dropdownContent {
  display: flex;
  flex-direction: column;
}

.dropdown:hover .dropdownParent{
  color: #84E279;
}

.dropdownItem a {
  padding: 12px 16px;
  color: rgb(255 255 255 / 80%);
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;

  &:hover {
    color: #84E279;
    background: rgb(255 255 255 / 7%);
    border-radius: 4px;
  }
}

.dropdownItemLogout {
  padding: 12px 16px;
  color: var(--color-brand-secondary);
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;

  &:hover {
    color: white;
    background: var(--color-brand-secondary);
    border-radius: 4px;
  }
}

.separator {
  height: 1px;
  margin: 4px 0;
  background: #3F4242;
}

.email {
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdownTrigger {
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
  justify-content: center;
}



.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 32px;
}

.spinner {
  width: 20px;
  height: 20px;
  margin: 20px auto;
  border: 2px solid rgb(0 0 0 / 10%);
  border-left-color: var(--color-primary-lightgreen);
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
