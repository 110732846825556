@font-face {
  font-weight: 400;
  font-family: Roboto;
  font-style: normal;
  src: local('Roboto'), url('/assets/fonts/Roboto-Regular.woff') format('woff'),
    url('/assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('/assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-weight: 400;
  font-family: PlexSans;
  font-style: normal;
  src: local('PlexSans'), url('/assets/fonts/IBMPlexSans.woff') format('woff'),
    url('/assets/fonts/IBMPlexSans.woff2') format('woff2'),
    url('/assets/fonts/IBMPlexSans.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-weight: 300;
  font-family: Roboto;
  font-style: normal;
  src: local('RobotoLight'),
    url('/assets/fonts/Roboto-Light.woff') format('woff'),
    url('/assets/fonts/Roboto-Light.woff2') format('woff2'),
    url('/assets/fonts/Roboto-Light.ttf') format('truetype');
  font-display: swap;
}

html {
  box-sizing: border-box;
  font-size: var(--app-font-size);
  font-family: var(--app-font-family);
  line-height: 1.5;
}

div {
  -webkit-tap-highlight-color: transparent;
}

button {
  font-size: 14px;
  font-family: var(--app-font-family);
  border-style: none;
  cursor: pointer;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  color: var(--color-text-hi);
  font-family: var(--app-font-family);
  -webkit-font-smoothing: antialiased;
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
