@use 'src/styles/utils' as *;

.dropdown {
  position: relative;
  display: flex;
  gap: .5rem;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  height: 100%;
  padding: 0 10px;
  background-color: var(--color-primary-lightgreen);
  cursor: pointer;
}

.selectedItem {
  margin: 0;
  color: #000;
  font-size: 16px;
}

.menu {
  position: absolute;
  top: 110%;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 100%;
  padding: 10px;
  background-color: #000;
  border-top: none;
}

.menuItem:hover a {
  color: var(--color-primary-lightgreen);
}