.container {
  position: relative;
  overflow: visible;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-20) var(--space-70) var(--space-20) var(--space-25);
  border: none;

  &::after {
    position: absolute;
    top: 50%;
    right: var(--size-25);
    width: var(--size-25);
    height: var(--size-40);
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxMCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgNkw1IDEwTDkgNiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLW9wYWNpdHk9IjAuNCIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=');
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(-50%);
    content: '';
  }
}

.label {
  margin-left: var(--space-25);
  color: var(--color-white);
  font-size: var(--text-regular);
}

.wrapper {
  position: absolute;
  right: 0;
  z-index: var(--z-index-popover);
  display: none;
  width: 264px;
  background-color: var(--color-surface-700);
  transform: translate(0, 2px);
}

.isOpen {
  display: block;
}

.group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: var(--space-25);
  column-gap: var(--space-70);
  row-gap: var(--space-25);

  &:first-of-type {
    border-bottom: 1px solid var(--color-border-md);
  }
}

.option {
  display: flex;
  align-items: center;
  color: var(--color-text-lo);
  cursor: pointer;
  opacity: 0.5;
}

.optionLabel {
  margin-left: var(--space-20);
  font-size: var(--text-regular);
}

.selectedOption {
  color: var(--color-white);
  opacity: 1;
}
