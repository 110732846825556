@use 'src/styles/utils' as *;

.form {
  display: flex;
  flex-direction: column;
  width: 320px;
  margin: 0;
  padding: 16px;
  text-align: center;
  background-color: #2a2d2d;
  border-radius: 8px;
  box-shadow: 1px 1px 10px rgb(0 0 0 / 16%);
}

.formGroup {
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 20px;
}

.label {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  opacity: 0.8;
}

.input {
  height: 34px;
  margin-top: 10px;
  padding: 10px;
  color: #fff;
  background-color: #1d1f1e;
  border: none;
  border-radius: 6px;
  outline: none;
}

.button {
  width: 100%;
  height: 40px;
  color: black;
  background-color: #84c47c;
  border: none;
  border-radius: 6px;
}

.button:hover {
  cursor: pointer;
}

.error {
  margin-top: 0.5rem;
  color: red;
  font-size: 0.8rem;
}
