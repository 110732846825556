.universalloader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 80;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #1d1f1e;

  .wrapper {
    position: relative;
    width: 134px;
    height: 134px;
    overflow: hidden;
    backface-visibility: hidden;
  }

  .logo {
    z-index: 80;
    width: 100%;
    height: 100%;
    background: url('/assets/backLogo.svg') no-repeat center center;
    animation: fade-in-from-none 4s linear infinite;
  }

  &--translucent {
    z-index: 20000;
    background-color: rgb(29 31 30 / 90%);
  }

  @keyframes fade-in-from-none {
    0% {
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    70% {
      opacity: 1;
    }

    90% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }
}
