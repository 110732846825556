.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  overflow: visible;
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
  vertical-align: top;
  border-style: solid;
  border-width: var(--size-01);
  border-radius: var(--radius-xs);
  cursor: pointer;
  isolation: isolate;
  user-select: none;
  touch-action: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.primary {
  color: var(--color-black);
  background: var(--color-brand-primary);
  border-color: var(--color-brand-primary);
}

.secondary {
  color: var(--color-brand-primary);
  background: var(--color-surface-700);
  border-color: var(--color-surface-200);
}

.tertiary {
  color: var(--color-text-md);
  background: var(--color-surface-700);
  border-color: var(--color-surface-200);
}

.sm {
  padding: var(--space-10) var(--space-30);
  font-size: var(--text-regular);
  line-height: var(--leading-21);
}

.md {
  padding: var(--space-20) var(--space-40);
  font-size: var(--text-lead);
  line-height: var(--leading-21);
}
