.tag {
  display: inline-block;
  background-color: var(--color-surface-500);
  border-radius: var(--radius-sm);
}

.small {
  padding: 0 var(--space-10);
  color: var(--color-text-md);
  font-size: var(--text-small);
}

.regular {
  padding: 1px var(--space-20);
  color: var(--color-text-md);
  font-size: var(--text-regular);
}
